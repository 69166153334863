import { Bubble as BubbleType, isAudioBubble, isTextBubble } from 'common/types'
import { DateTime } from 'luxon'
import React from 'react'
import { AudioPlayer } from '../AudioPlayer'
import { cn } from '../ui/cn'
import { useUser } from '../UserProvider'

interface Props {
  bubble: BubbleType
  author?: string
}

export const Bubble: React.FC<Props> = ({ bubble, author }) => {
  const { uid } = useUser()

  const isUserBubble = bubble.userId === uid

  return (
    <div
      className={`flex flex-row ${
        isUserBubble ? 'justify-end' : 'justify-start'
      }`}
    >
      <div
        className={cn(
          'text-card-foreground m-3 flex w-full max-w-[90%] flex-col gap-2 rounded-b-lg p-4',
          isUserBubble
            ? 'bg-card justify-end rounded-tl-lg text-right'
            : 'bg-accent text-accent-foreground justify-start rounded-tr-lg text-left',
        )}
      >
        <div
          className={cn(
            'flex items-center space-x-2',
            isUserBubble ? 'justify-end' : 'justify-start',
          )}
        >
          {author && <span className="font-bold">{author}</span>}
          <span className="text-sm font-normal">
            {formatDate(bubble.timestamp)}
          </span>
        </div>
        <div className="flex flex-col space-x-2">
          {isAudioBubble(bubble) && <AudioPlayer url={bubble.soundURL} />}
          {isTextBubble(bubble) && (
            <div className="whitespace-pre-wrap">{bubble.text}</div>
          )}
          {bubble.image !== undefined && (
            <img
              src={bubble.image.URL}
              className="mt-2 h-auto max-h-64 max-w-full object-contain"
            />
          )}
        </div>
      </div>
    </div>
  )
}

function formatDate(timestamp: number) {
  return DateTime.fromMillis(timestamp).toLocaleString({
    weekday: 'long',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  })
}
