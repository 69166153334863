import { Trans } from '@lingui/macro'
import React from 'react'
import { Deferred } from 'shared/utils/web/deferred'
import { DialogButton } from '../../components/Button'
import { Dialog, USER_CLOSED_DIALOG } from '../../components/Dialog'

export const ShowSuccessDialog: React.FC<{
  deferred: Deferred<void>
}> = ({ deferred }) => {
  return (
    <Dialog onClose={() => deferred.reject(USER_CLOSED_DIALOG)}>
      <Trans>Merci pour votre message&nbsp;!</Trans>
      <div className="text-center text-4xl">👍</div>
      <Trans>Il sera pris en compte par notre équipe.</Trans>
      <DialogButton onClick={() => deferred.resolve()}>
        <Trans>Fermer</Trans>
      </DialogButton>
    </Dialog>
  )
}
