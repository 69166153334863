import { useEffect, useRef } from 'react'

const useIntersectionObserver = (
  targetRef: React.RefObject<Element>,
  callback: IntersectionObserverCallback,
  options?: IntersectionObserverInit,
) => {
  const observer = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    const target = targetRef.current
    if (target) {
      observer.current = new IntersectionObserver(callback, options)
      observer.current.observe(target)
    }

    return () => {
      if (observer.current && target) {
        observer.current.unobserve(target)
        observer.current.disconnect()
      }
    }
  }, [targetRef, options, callback])

  return targetRef
}

export default useIntersectionObserver
