import * as React from "react";
const SvgAwake = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 368 368", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M184,0C82.544,0 0,82.544 0,184C0,285.456 82.544,368 184,368C285.456,368 368,285.456 368,184C368,82.544 285.456,0 184,0ZM184,352C91.36,352 16,276.64 16,184C16,91.36 91.36,16 184,16C276.64,16 352,91.36 352,184C352,276.64 276.64,352 184,352Z", style: {
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M144,152C144,138.768 133.232,128 120,128C106.768,128 96,138.768 96,152C96,165.232 106.768,176 120,176C133.232,176 144,165.232 144,152ZM112,152C112,147.592 115.592,144 120,144C124.408,144 128,147.592 128,152C128,156.408 124.408,160 120,160C115.592,160 112,156.408 112,152Z", style: {
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M248,128C234.768,128 224,138.768 224,152C224,165.232 234.768,176 248,176C261.232,176 272,165.232 272,152C272,138.768 261.232,128 248,128ZM248,160C243.592,160 240,156.408 240,152C240,147.592 243.592,144 248,144C252.408,144 256,147.592 256,152C256,156.408 252.408,160 248,160Z", style: {
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M263.336,237.04C260.04,234.088 254.12,233.994 250.04,235.664C231.352,243.312 209.288,249 184,249C158.72,249 139.648,243.312 117.96,235.664C113.793,234.194 108.96,235.088 105.664,238.04C102.368,240.984 103.945,246.674 108.04,248.336C132.76,258.368 154.176,265 184,265C213.832,265 237.248,258.36 259.96,248.336C264.003,246.552 266.624,239.984 263.336,237.04Z", style: {
  fillRule: "nonzero"
} }));
export default SvgAwake;
