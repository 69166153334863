import * as React from "react";
const SvgTv = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 104 100", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M84.1,32.166l-34.2,-0l12.1,-16.2c0.3,-0.4 0.4,-0.9 0.3,-1.4c-0.1,-1 -1,-1.7 -1.9,-1.6l-0.1,0c-0.5,0.1 -0.9,0.3 -1.2,0.7l-13.7,18.4l-2.6,-0l-13.8,-18.3c-0.6,-0.8 -1.8,-0.9 -2.6,-0.4c-0.4,0.3 -0.6,0.7 -0.7,1.2c-0.1,0.5 0,1 0.3,1.4l12.1,16.2l-18.3,-0c-3.6,-0 -6.5,2.9 -6.5,6.5l0,42c0,3.6 2.9,6.5 6.5,6.5l64.3,-0c3.6,-0 6.5,-2.9 6.5,-6.5l0,-42c0,-3.6 -2.9,-6.5 -6.5,-6.5Zm-67.1,6.5c0,-1.5 1.2,-2.8 2.8,-2.8l64.3,-0c1.5,-0 2.8,1.2 2.8,2.8l0,42c0,1.5 -1.2,2.8 -2.8,2.8l-64.3,0c-1.5,0 -2.8,-1.2 -2.8,-2.8l0,-42Z", style: {
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M26.1,79.066l41.1,-0c3.6,-0 6.5,-2.9 6.5,-6.5l0,-26c0,-3.6 -2.9,-6.5 -6.5,-6.5l-41.1,-0c-3.6,-0 -6.5,2.9 -6.5,6.5l0,26c0,3.6 2.9,6.5 6.5,6.5Zm0,-35.2l41.1,-0c1.5,-0 2.8,1.2 2.8,2.8l0,26c0,1.5 -1.2,2.8 -2.8,2.8l-41.1,0c-1.5,0 -2.8,-1.2 -2.8,-2.8l0,-26c0,-1.6 1.3,-2.8 2.8,-2.8Z", style: {
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M80.4,56.466c3,0 5.5,-2.4 5.5,-5.5c0,-3.1 -2.4,-5.5 -5.5,-5.5c-3,0 -5.5,2.4 -5.5,5.5c0.1,3 2.5,5.5 5.5,5.5Zm-1.7,-5.4c0,-1 0.8,-1.8 1.8,-1.8c1,-0 1.8,0.8 1.8,1.8c0,1 -0.8,1.8 -1.8,1.8c-1.1,-0.1 -1.8,-0.8 -1.8,-1.8Z", style: {
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M80.4,72.066c3,-0 5.5,-2.4 5.5,-5.5c0,-3 -2.4,-5.5 -5.5,-5.5c-3,-0 -5.5,2.4 -5.5,5.5c0.1,3.1 2.5,5.5 5.5,5.5Zm-1.7,-5.4c0,-1 0.8,-1.8 1.8,-1.8c1,-0 1.8,0.8 1.8,1.8c0,1 -0.8,1.8 -1.8,1.8c-1.1,0 -1.8,-0.8 -1.8,-1.8Z", style: {
  fillRule: "nonzero"
} }));
export default SvgTv;
