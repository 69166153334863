import { FirebaseCall } from 'common/types'
import Peer, { MediaConnection } from 'peerjs'
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { onError } from 'shared/utils/web/error'
import { useUser } from '../../components/UserProvider'
import { remove, set, update } from '../../firebaseMethods'
import { useFirebase } from '../../hooks/useFirebase'

export const EmergencyCallContext = createContext<{
  startEmergencyCall: () => Promise<void>
  hangUpCall: () => void
  remoteVideoRef: React.MutableRefObject<HTMLVideoElement | null>
  localVideoRef: React.MutableRefObject<HTMLVideoElement | null>
  isCallInProgress: boolean
  isCallAnswered: boolean
  amICalling: boolean
  amICalled: boolean
}>({
  startEmergencyCall: () => new Promise((resolve) => resolve()),
  hangUpCall: () => {
    // do nothing
  },
  remoteVideoRef: { current: null },
  localVideoRef: { current: null },
  isCallInProgress: false,
  isCallAnswered: false,
  amICalling: false,
  amICalled: false,
})

export const EmergencyCallProvider: React.FC<
  React.PropsWithChildren<{ facilityId: string }>
> = ({ facilityId, children }) => {
  const peerRef = useRef<Peer | null>(null)
  const callRef = useRef<MediaConnection | null>(null)

  const localVideoRef = useRef<HTMLVideoElement | null>(null)
  const remoteVideoRef = useRef<HTMLVideoElement | null>(null)

  const user = useUser()

  const firebaseCallPath = `calls/${facilityId}` as const
  const { data: firebaseCall } = useFirebase(firebaseCallPath)

  const startEmergencyCall = useCallback(async () => {
    const peer = (peerRef.current = new Peer())

    await new Promise((resolve) => peer.on('open', resolve))

    console.log('my peer id', peer.id)

    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    })

    peer.on('close', () => {
      console.log('closing local peer')
      if (localVideoRef.current) {
        localVideoRef.current.pause()
        localVideoRef.current.srcObject = null
      }
      stream.getTracks().forEach((track) => track.stop())
    })

    peer.on('error', onError)

    if (localVideoRef.current) localVideoRef.current.srcObject = stream

    const setupCall = (call: MediaConnection) => {
      call.on('stream', (remoteStream) => {
        console.log('call stream')
        if (remoteVideoRef.current)
          remoteVideoRef.current.srcObject = remoteStream
      })

      call.on('close', () => {
        console.log('call closed')
        if (remoteVideoRef.current) {
          remoteVideoRef.current.pause()
          remoteVideoRef.current.srcObject = null
        }
      })

      call.on('error', onError)
    }

    if (firebaseCall && !firebaseCall.calleeId) {
      const call = (callRef.current = peer.call(firebaseCall.callerId, stream))
      const callUpdate: Required<
        Omit<FirebaseCall, 'id' | 'callerId' | 'callerEmail'>
      > = {
        calleeId: peer.id,
        calleeEmail: user.email,
      }
      update(firebaseCallPath, callUpdate)
      setupCall(call)
    } else {
      const call: FirebaseCall = {
        id: Date.now(),
        callerId: peer.id,
        callerEmail: user.email,
      }
      set(firebaseCallPath, call)
      peer.on('call', (call) => {
        callRef.current = call
        console.log('receive call from', call.peer)
        call.answer(stream)
        setupCall(call)
      })
    }
  }, [firebaseCall, firebaseCallPath, user.email])

  // Used by android to start a call using keyword recognition
  window.startEmergencyCall = startEmergencyCall

  const hangUpCall = useCallback(() => {
    remove(firebaseCallPath)
  }, [firebaseCallPath])

  const [isCallInProgress, setIsCallInProgress] = useState(false)

  useEffect(() => {
    if (firebaseCall) {
      // Stop notification when the call is answered, by us or someone else
      if (firebaseCall.calleeId && window.Android?.stopNotification) {
        window.Android.stopNotification()
      }
      setIsCallInProgress(true)
    } else {
      if (callRef.current) {
        callRef.current.close()
        callRef.current = null
      }
      if (peerRef.current) {
        peerRef.current.destroy()
        peerRef.current = null
      }
      // In case call is auto-deleted after delay
      // Need to track previous state to differentiate with
      // the no firebaseCall state on startup
      if (window.Android?.stopNotification && isCallInProgress) {
        window.Android.stopNotification()
      }
      setIsCallInProgress(false)
    }
  }, [firebaseCall, isCallInProgress])

  useEffect(() => {
    // When any of the two persons in the call closes its webView, stop the call
    if (
      firebaseCall &&
      peerRef.current &&
      (firebaseCall.callerId === peerRef.current.id ||
        firebaseCall.calleeId === peerRef.current.id)
    ) {
      const handleUnload = () => {
        remove(firebaseCallPath)
      }
      window.addEventListener('beforeunload', handleUnload)
      return () => window.removeEventListener('beforeunload', handleUnload)
    }

    return
  }, [firebaseCall, firebaseCallPath])

  const contextValue = useMemo(() => {
    const isCallInProgress = !!firebaseCall

    const amICalling =
      isCallInProgress &&
      peerRef.current !== null &&
      firebaseCall.callerId === peerRef.current.id

    const amICalled =
      isCallInProgress &&
      peerRef.current !== null &&
      firebaseCall.calleeId === peerRef.current.id

    const isCallAnswered = isCallInProgress && !!firebaseCall.calleeId

    return {
      startEmergencyCall,
      hangUpCall,
      remoteVideoRef,
      localVideoRef,
      isCallInProgress,
      isCallAnswered,
      amICalling,
      amICalled,
    }
  }, [firebaseCall, startEmergencyCall, hangUpCall])

  return (
    <EmergencyCallContext.Provider value={contextValue}>
      {children}
    </EmergencyCallContext.Provider>
  )
}
