import { Moon, Sun } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import {
  Theme,
  currentTheme,
  setTheme as setTheme_,
} from '../../components/Theme'
import { Button } from '../../components/ui/Button'

const StyledLink: React.FC<React.PropsWithChildren<{ to: string }>> = ({
  to,
  children,
}) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      isActive ? 'border-b-2 border-current px-4 py-1' : 'px-4 py-1'
    }
  >
    {children}
  </NavLink>
)

export const Tabs: React.FC = () => {
  return (
    <div className="z-10 flex flex-row gap-4 bg-gray-700 px-3 shadow shadow-neutral-800">
      <StyledLink to="alerts">Alertes</StyledLink>
      <StyledLink to="live">AS</StyledLink>
      <StyledLink to="admin">Admin</StyledLink>
      <StyledLink to="exports">Exports</StyledLink>
      {import.meta.env.DEV && <ThemeSwitch />}
    </div>
  )
}

const ThemeSwitch = () => {
  const [theme, setTheme] = useState<Theme>(currentTheme())

  useEffect(() => {
    setTheme_(theme)
  }, [theme])

  return (
    <Button
      size="icon"
      variant="ghost"
      onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
    >
      {theme === 'dark' ? (
        <Moon className="h-6 w-6" />
      ) : (
        <Sun className="h-6 w-6" />
      )}
    </Button>
  )
}
