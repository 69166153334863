import { createContext, useCallback, useEffect, useState } from 'react'
import { RoomNames } from 'shared/types/live'
import { Room } from 'shared/types/utils'
import { asFirebaseKey } from 'shared/utils/firebase'
import { useUser } from '../../components/UserProvider'
import { set } from '../../firebaseMethods'
import { useFirebase } from '../../hooks/useFirebase'

interface Context {
  roomNames: RoomNames
  setRoomName: (room: Room, name: string) => void
}

export const RoomNamesContext = createContext<Context>({
  setRoomName: (_room: Room, _name: string) => {
    /* empty */
  },
  roomNames: {},
})

interface Props {
  facilityId: string
  rooms: Room[]
}

function roomNameStorageKey(room: string) {
  return `roomName-${room}`
}

/* A global room names provider for a given facility.

Pretty involved since the database synchronisation must be made optional.
It is activated in staging environment, or only for AS users.

An optional intermediary RoomNamesSyncer component is added, which takes
care of database synchronisation.

A common RoomNamesSetter component is used, directly or by RoomNamesSyncer to
offet read / write methods to Room components. It has parameters to update 
from / to database, which are mocked when database sync is disabled.
*/
export const RoomNamesProvider: React.FC<React.PropsWithChildren<Props>> = ({
  facilityId,
  rooms,
  children,
}) => {
  const user = useUser()

  // Only AS have access to database name synchronisation
  if (user.role === 'AS')
    return (
      <RoomNamesSyncer facilityId={facilityId} rooms={rooms}>
        {children}
      </RoomNamesSyncer>
    )
  else
    return (
      <RoomNamesSetter
        rooms={rooms}
        broadcastRoomName={(_room: Room, _name: string) => {
          /* empty */
        }}
        roomNamesDelta={{}}
      >
        {children}
      </RoomNamesSetter>
    )
}

export const RoomNamesSyncer: React.FC<React.PropsWithChildren<Props>> = ({
  facilityId,
  rooms,
  children,
}) => {
  const [previousRoomNames, setPreviousRoomNames] = useState<RoomNames>({})
  const [roomNamesDelta, setRoomNamesDelta] = useState<RoomNames>({})

  const { data: broadcastedRoomNames } = useFirebase(`roomNames/${facilityId}`)

  const broadcastRoomName = useCallback(
    (room: Room, name: string) => {
      set(`roomNames/${facilityId}/${asFirebaseKey(room)}`, name)
    },
    [facilityId],
  )

  useEffect(() => {
    if (!broadcastedRoomNames) return

    const roomNamesDelta = Object.entries(
      broadcastedRoomNames,
    ).reduce<RoomNames>((acc, [room, name]) => {
      if (previousRoomNames[room] !== name) acc[room] = name
      return acc
    }, {})

    setRoomNamesDelta(roomNamesDelta)
    setPreviousRoomNames(broadcastedRoomNames)
  }, [previousRoomNames, broadcastedRoomNames])

  return (
    <RoomNamesSetter
      rooms={rooms}
      broadcastRoomName={broadcastRoomName}
      roomNamesDelta={roomNamesDelta}
    >
      {children}
    </RoomNamesSetter>
  )
}

interface RoomNamesSetterProps {
  rooms: Room[]
  broadcastRoomName: (_room: Room, _name: string) => void
  roomNamesDelta: RoomNames
}

export const RoomNamesSetter: React.FC<
  React.PropsWithChildren<RoomNamesSetterProps>
> = ({ rooms, broadcastRoomName, roomNamesDelta, children }) => {
  const [roomNames, setRoomNames] = useState(
    rooms.reduce<RoomNames>((acc, room) => {
      acc[room] = localStorage.getItem(roomNameStorageKey(room)) ?? ''
      return acc
    }, {}),
  )

  const setRoomName = useCallback(
    (room: Room, name: string) => {
      localStorage.setItem(roomNameStorageKey(room), name)
      broadcastRoomName(room, name)
      setRoomNames((roomNames) => ({ ...roomNames, [room]: name }))
    },
    [broadcastRoomName],
  )

  useEffect(() => {
    setRoomNames((roomNames) => ({ ...roomNames, ...roomNamesDelta }))
  }, [roomNamesDelta])

  return (
    <RoomNamesContext.Provider value={{ roomNames, setRoomName }}>
      {children}
    </RoomNamesContext.Provider>
  )
}
