import { DateTime, Duration } from 'luxon'
import React, { useState } from 'react'
import { Deferred } from 'shared/utils/web/deferred'
import { GrayButton } from '../../components/Button'
import { Dialog, USER_CLOSED_DIALOG } from '../../components/Dialog'
import { Select } from '../../components/Select'

const getTimestamp = (duration: string) => {
  return DateTime.now().plus(Duration.fromISOTime(duration)).toMillis()
}

export const SnoozeAlertsDialog: React.FC<{
  deferred: Deferred<number>
}> = ({ deferred }) => {
  const [duration, setDuration] = useState('00:00')

  return (
    <Dialog onClose={() => deferred.reject(USER_CLOSED_DIALOG)}>
      Durée de la sourdine :
      <Select value={duration} onChange={(e) => setDuration(e.target.value)}>
        <option value="00:00">Arrêt</option>
        <option value="00:15">15 minutes</option>
        <option value="00:30">30 minutes</option>
        <option value="00:45">45 minutes</option>
        <option value="01:00">1 heure</option>
        <option value="02:00">2 heures</option>
        <option value="04:00">4 heures</option>
        <option value="12:00">Toute la nuit</option>
      </Select>
      <div className="flex flex-row gap-3">
        <GrayButton
          className="flex-auto"
          onClick={() => deferred.reject(USER_CLOSED_DIALOG)}
        >
          Annuler
        </GrayButton>
        <GrayButton
          className="flex-auto"
          onClick={() => deferred.resolve(getTimestamp(duration))}
        >
          Confirmer
        </GrayButton>
      </div>
    </Dialog>
  )
}
