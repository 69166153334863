import { DateTime } from 'luxon'
import { useState } from 'react'
import { noonDate } from 'shared/utils/time'
import { FacilitySelector } from '../../components/FacilitySelector'
import { Input } from '../../components/Input'
import { DevicesAlerts } from './DevicesAlerts'

const getMaxDate = () => noonDate(DateTime.now())

export const OnCall = () => {
  const [date, setDate] = useState(getMaxDate)

  return (
    <div className="flex flex-1 flex-col overflow-auto p-3 text-base text-white">
      <div className="mb-4 flex flex-row flex-wrap items-center justify-center gap-4">
        <Input
          value={date}
          type="date"
          max={getMaxDate()}
          onChange={(e) => setDate(e.target.value)}
        />
        <FacilitySelector />
      </div>

      <DevicesAlerts date={date} />
    </div>
  )
}
