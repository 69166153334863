// Callback function on the global window object, to be called from the Android WebView
import { captureMessage } from '@sentry/react'
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { goOnline } from 'firebase/database'
import { lastUserEmailStorageKey } from 'shared/hooks/createUseAuth'
import { onError } from 'shared/utils/web/error'
import { ACTIVITY_RESUMED_EVENT } from './constants'
import { auth, database } from './firebase'

window.onActivityResumed = function () {
  console.log('GILLES onActivityResumed')
  document.dispatchEvent(new Event(ACTIVITY_RESUMED_EVENT))
  console.log('GILLES Go online')
  goOnline(database)
  console.log('GILLES Go online done')
}

export const lastUserPasswordStorageKey = `lastUserPassword`

window.loginUser = async function (email: string, password: string) {
  try {
    await signInWithEmailAndPassword(auth, email, password)

    // Done as soon as web auth succeeds, even if android auth below crashes...
    localStorage.setItem(lastUserEmailStorageKey, email)
    localStorage.setItem(lastUserPasswordStorageKey, password)

    if (window.Android?.saveCredentials) {
      window.Android.saveCredentials(email, password)
    }
  } catch (error) {
    captureMessage(`Unable to login for ${email}`)
    onError(error)
    throw error
  }
}

window.logoutUser = function () {
  signOut(auth)
}

// Defined in the EmergencyCall component
// window.startEmergencyCall = ...
