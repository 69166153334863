import { User as SentryUser, setUser } from '@sentry/react'
import {
  Auth,
  User,
  getRedirectResult,
  onAuthStateChanged,
} from 'firebase/auth'
import { useEffect, useState } from 'react'
import {
  AsyncState,
  dataState,
  errorState,
  loadingState,
} from '../types/asyncState'
import { osoDomainName } from '../utils/url'
import { onError } from '../utils/web/error'

export const lastUserEmailStorageKey = `lastUserEmail`

export function createUseAuth(auth: Auth) {
  return function useAuth() {
    const [state, setState] = useState<AsyncState<User | null>>(loadingState())

    useEffect(
      () => {
        getRedirectResult(auth).catch((error) => {
          onError(error)
          setState(errorState(error))
        })
      },
      [], // Empty list, register only once
    )

    useEffect(() => {
      // This method was seen not ending properly, probably with a bad connection,
      // resulting in no setUser call
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        setState(dataState(user))

        if (user === null) {
          let lastUserEmail = localStorage.getItem(lastUserEmailStorageKey)

          if (lastUserEmail === null) {
            lastUserEmail = `fake-${Math.random()
              .toString(36)
              .slice(2)}@${osoDomainName}`
            localStorage.setItem(lastUserEmailStorageKey, lastUserEmail)
          }

          setUser({ id: lastUserEmail, email: lastUserEmail })
        } else {
          const sentryUser = {
            id: user.uid,
            email: user.email ?? `no-email-${user.uid}@${osoDomainName}`,
          } satisfies SentryUser

          setUser(sentryUser)
          localStorage.setItem(lastUserEmailStorageKey, sentryUser.email)
        }
      })

      return () => {
        unsubscribe()
        setState(loadingState())
      }
    }, [])

    return state
  }
}
