import { z } from 'zod'

export const ROLE_DURATION = 12 /* hours */ * 60 // minutes, minimum 15, maximum 12 hours

export const AuditAlertSchema = z.object({
  id: z.string(),
  facility: z.string(),
  serial: z.string(),
  // Received as a string, that should be coerced
  time: z.coerce.number(),
  room: z.string(),
  type: z.string(),
})

export type AuditAlert = z.infer<typeof AuditAlertSchema>

export interface AddAlertResponse {
  webSiteUrl: string
  expiration: number
}
