import { Trans } from '@lingui/macro'
import React, { useEffect, useState } from 'react'
import { Deferred } from 'shared/utils/web/deferred'
import { DialogButton } from '../../components/Button'
import {
  Dialog,
  USER_BACK_BUTTON,
  USER_CLOSED_DIALOG,
} from '../../components/Dialog'
import {
  Theme,
  currentTheme,
  setTheme as setTheme_,
} from '../../components/Theme'
import Arrow from '../../icons/arrow.svg?react'

type Position = 'left' | 'middle' | 'right'

function pillClassName(position: Position, isSelected: boolean) {
  const borderClass: Record<Position, string> = {
    left: 'border rounded-l-lg',
    middle: 'border-y',
    right: 'border rounded-r-lg',
  }
  return `border-black px-4 py-1 ${borderClass[position]} ${
    isSelected ? 'bg-black text-white' : ''
  }`
}

export const SettingsDialog: React.FC<{
  deferred: Deferred<void>
}> = ({ deferred }) => {
  const [theme, setTheme] = useState<Theme>(currentTheme())

  useEffect(() => {
    setTheme_(theme)
  }, [theme])

  return (
    <Dialog onClose={() => deferred.reject(USER_CLOSED_DIALOG)}>
      <div
        className="flex cursor-pointer flex-row items-center space-x-2"
        onClick={() => deferred.reject(USER_BACK_BUTTON)}
      >
        <Arrow className="w-4" fill="currentColor" />
        <span className="flex-1">
          <Trans>Réglages</Trans>
        </span>
      </div>

      <div className="flex flex-1 flex-col space-y-3">
        <div>
          <Trans>Thème de couleurs :</Trans>
        </div>
        <div className="flex flex-row justify-around">
          <div className="flex flex-row">
            <div
              className={pillClassName('left', theme === 'light')}
              onClick={() => setTheme('light')}
            >
              <Trans>Clair</Trans>
            </div>
            <div
              className={pillClassName('middle', theme === 'auto')}
              onClick={() => setTheme('auto')}
            >
              <Trans>Auto</Trans>
            </div>
            <div
              className={pillClassName('right', theme === 'dark')}
              onClick={() => setTheme('dark')}
            >
              <Trans>Sombre</Trans>
            </div>
          </div>
        </div>
        <div className="text-base">
          <Trans>
            Le mode Auto est Clair ou Sombre selon l'heure de la journée.
          </Trans>
        </div>
      </div>

      <div className="flex flex-row">
        <DialogButton onClick={() => deferred.reject(USER_CLOSED_DIALOG)}>
          <Trans>Fermer</Trans>
        </DialogButton>
      </div>
    </Dialog>
  )
}
