import { Database, DataSnapshot, onValue, ref } from 'firebase/database'
import { useEffect, useState } from 'react'
import { DatabaseSubType } from '../firebase/databaseSubType'
import { convertNullToUndefined, IsPathValid } from '../firebase/typedMethods'
import {
  AsyncState,
  dataState,
  errorState,
  loadingState,
} from '../types/asyncState'
import { onError } from '../utils/web/error'

export function createUseFirebase<DatabaseSchema>(database: Database) {
  return function useFirebase<
    Path extends string,
    DatabaseType = DatabaseSubType<DatabaseSchema, Path>,
  >(path: IsPathValid<DatabaseType> extends true ? Path : never) {
    const [state, setState] = useState<AsyncState<DatabaseType>>(loadingState())

    useEffect(() => {
      const unsubscribe = onValue(
        ref(database, path),
        (snapshot: DataSnapshot) => {
          setState(dataState(convertNullToUndefined(snapshot.val())))
        },
        (error: Error) => {
          setState(errorState(error))
          onError(error)
        },
      )

      return () => {
        unsubscribe()
        setState(loadingState())
      }
    }, [path])

    return state
  }
}
