import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'
import 'shared/utils/luxon'
import './androidCallbacks'
import { RELEASE_NAME } from './constants'
import './index.css'
import { App } from './modules/App/App'

Sentry.init({
  enabled: !!import.meta.env.VITE_VERSION,
  dsn: 'https://ef4dc1821b604171af3dcda4902eb709@o566331.ingest.sentry.io/5709054',
  integrations: [Sentry.browserTracingIntegration()],
  environment: import.meta.env.MODE,
  tracesSampleRate: 0.2,
  release: RELEASE_NAME,
})

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
root.render(<App />)
