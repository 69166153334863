import React, { useState } from 'react'
import { Dialog } from 'shared/components/Dialog'
import SoundButton from '../icons/sound.svg?react'

export const Audit: React.FC<{ auditUrl: string }> = ({ auditUrl }) => {
  const [showAudit, setShowAudit] = useState(false)

  // Warning - style should be kept in sync with Player
  return (
    <>
      <div
        className={`flex h-16 w-16 cursor-pointer flex-col items-center justify-center rounded-full border-2 border-current`}
        onClick={() => setShowAudit(true)}
      >
        <SoundButton className="w-8 fill-current stroke-current" />
      </div>

      {showAudit && (
        <Dialog onClose={() => setShowAudit(false)}>
          <div className="flex flex-col border border-neutral-300">
            <div className="flex-1 overflow-y-auto">
              <iframe className="h-[50vh] w-[90vw]" src={auditUrl} />
            </div>
            <div
              className="bg-background-dialog dark:bg-background-dialog-dark text-dialog dark:text-dialog-dark w-full cursor-pointer py-2 text-center text-xl"
              onClick={() => setShowAudit(false)}
            >
              Fermer
            </div>
          </div>
        </Dialog>
      )}
    </>
  )
}
