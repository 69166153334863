import React from 'react'

export const ListItem: React.FC<
  React.PropsWithChildren<{ onClick?: () => void }>
> = ({ children, onClick }) => {
  return (
    <div className="flex flex-row space-x-2 align-baseline">
      <span>●</span>
      <span
        className={`flex-1 ${onClick ? 'cursor-pointer underline' : ''}`}
        onClick={onClick}
      >
        {children}
      </span>
    </div>
  )
}
