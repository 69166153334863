import { captureMessage } from '@sentry/react'
import { useEffect } from 'react'
import { serverTimestamp } from 'shared/firebase/serverValue'
import { lastUserEmailStorageKey } from 'shared/hooks/createUseAuth'
import { fetch_ } from 'shared/utils/fetch'
import { asFirebaseKey } from 'shared/utils/firebase'
import { onError } from 'shared/utils/web/error'
import { auth } from '../../firebase'
import { remove, set } from '../../firebaseMethods'
import { useFirebase } from '../../hooks/useFirebase'
import { isPhoneCallEnabled } from '../Live/android'

export function PasswordReset() {
  // Crude way to detect android v2, where password is set by native code
  if (isPhoneCallEnabled()) return null

  const lastUserEmail = localStorage.getItem(lastUserEmailStorageKey)
  if (lastUserEmail === null) return null

  return <PasswordResetChecker email={lastUserEmail} />
}

function PasswordResetChecker({ email }: { email: string }) {
  const forcePasswordResetState = useFirebase(
    `forcePasswordReset/${asFirebaseKey(email)}`,
  )

  useEffect(() => {
    if (forcePasswordResetState.loading || forcePasswordResetState.error) return

    async function resetPassword() {
      try {
        const response = await fetch_(
          `https://europe-west1-oso-ari.cloudfunctions.net/resetUserPassword?email=${email}`,
        )

        const password = await response.text()

        if (!response.ok) throw new Error(password)

        await window.loginUser(email, password)

        const user = auth.currentUser

        if (user === null)
          throw new Error(`Auth mismatch, no user after login ${email}`)

        if (window.Android?.onUserLoggedIn)
          window.Android.onUserLoggedIn(user.uid)

        await set(`userPasswordReset/${user.uid}`, serverTimestamp())
        await remove(`forcePasswordReset/${asFirebaseKey(email)}`)

        captureMessage(`Successful user password reset ${email}, ${user.uid}`)
      } catch (error) {
        // Swallow error to prevent an infinite loop
        onError(error)
      }
    }

    if (forcePasswordResetState.data === true) resetPassword()
  }, [forcePasswordResetState, email])

  return null
}
