import { DateTime } from 'luxon'
import { useState } from 'react'
import { DialogButton } from '../../components/Button'
import { DialogInput } from '../../components/Input'
import { AlertsExport } from './AlertsExport'

const yesterday = DateTime.now().minus({ days: 1 }).toISODate()

export interface DateRange {
  startDate: string
  endDate: string
}

type ExportData = DateRange

export const Exports = () => {
  const [startDate, setStartDate] = useState<string>(
    DateTime.now().minus({ days: 7 }).toISODate(),
  )
  const [endDate, setEndDate] = useState<string>(yesterday)

  const [exportData, setExportData] = useState<ExportData>()

  function handleApply() {
    setExportData({ startDate, endDate })
  }

  return (
    <div className="flex flex-col gap-3 p-4">
      <div className="mb-8 flex flex-row flex-wrap gap-4">
        <label>
          Du
          <DialogInput
            className="ml-3"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </label>
        <label>
          au
          <DialogInput
            className="ml-3"
            type="date"
            value={endDate}
            max={yesterday}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </label>
        <DialogButton onClick={handleApply}>Appliquer</DialogButton>
      </div>
      {exportData && (
        <AlertsExport
          startDate={exportData.startDate}
          endDate={exportData.endDate}
        />
      )}
    </div>
  )
}
