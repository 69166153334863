import { DatabaseSchema } from 'common/databaseSchema'
import { CallStatusHistory } from 'common/types'
import React, { useMemo } from 'react'
import { DatabaseSubType } from 'shared/firebase/databaseSubType'
import { Nullable } from 'shared/hooks/createUseMergedFirebase'
import { useMergedFirebase } from '../../hooks/useMergedFirebase'

export const PhoneCallStatus: React.FC<{
  callStatus: CallStatusHistory
}> = ({ callStatus }) => {
  const currentStatus = callStatus.status[callStatus.status.length - 1]

  function getText() {
    switch (currentStatus) {
      case 'queued':
        return 'En attente'
      case 'initiated':
        return 'Démarré'
      case 'ringing':
        return 'Sonnerie'
      case 'in-progress':
        return 'En cours...'
      case 'completed':
        return `OK ${callStatus.duration}s`
      case 'busy':
        return 'Occupé'
      case 'failed':
        return 'Échec'
      case 'no-answer':
        return 'Sans réponse'
      case 'canceled':
        return 'Annulé'
    }
  }

  function isError() {
    switch (currentStatus) {
      case 'queued':
      case 'initiated':
      case 'ringing':
      case 'in-progress':
      case 'completed':
        return false
      case 'busy':
      case 'failed':
      case 'no-answer':
      case 'canceled':
        return true
    }
  }

  return (
    <span
      className={`rounded-full px-2 text-gray-800 ${
        isError() ? 'bg-red-400' : 'bg-teal-400'
      }`}
    >
      {getText()}
    </span>
  )
}

function getNonCompletedPhoneCalls(
  phoneCallsStatus: Nullable<DatabaseSubType<DatabaseSchema, 'phoneCalls'>>,
) {
  return Object.values(phoneCallsStatus).filter(
    (callStatusHistory) =>
      callStatusHistory &&
      callStatusHistory.status[callStatusHistory.status.length - 1] !==
        'completed',
  ) as CallStatusHistory[]
}

export const PhoneCallsStatus: React.FC<{
  phoneCalls: Record<string, string> | undefined
}> = ({ phoneCalls }) => {
  const refPathsMap = useMemo(
    () =>
      Object.values(phoneCalls ?? {}).reduce<Record<string, string>>(
        (acc, sid) => {
          acc[sid] = `phoneCalls/${sid}`
          return acc
        },
        {},
      ),
    [phoneCalls],
  )

  const {
    data: invalidCallsStatus,
    loading,
    error,
  } = useMergedFirebase(refPathsMap, getNonCompletedPhoneCalls)

  if (loading) return null
  if (error) return <div>Erreur</div>

  if (invalidCallsStatus.length === 0) return null

  return (
    <div className="flex flex-row flex-wrap items-center gap-2">
      <span>{'📞'}</span>
      {invalidCallsStatus.map((callStatus, index) => (
        <PhoneCallStatus key={index} callStatus={callStatus} />
      ))}
    </div>
  )
}
