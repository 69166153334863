/*
A fetch() promise only rejects when a network error is encountered 
(which is usually when there's a permissions issue or similar). 
A fetch() promise does not reject on HTTP errors (404, etc.).
Instead, a then() handler must check the Response.ok and/or 
Response.status properties.
*/
export async function fetch_(url: string, options?: RequestInit) {
  return fetch(url, options).then(async (response) => {
    if (response.ok) return response

    // An HTTP error should reject the promise
    const text = await response.text()
    const message = `${url} error ${response.status} ${text}`
    console.error(`fetch ${message}`)
    throw new Error(message)
  })
}
