import { useEffect, useRef } from 'react'

type Callback = () => void

export function useInterval(callback: Callback, delay: number | null) {
  const savedCallback = useRef<Callback>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current && savedCallback.current()
    }

    if (delay !== null) {
      tick()
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }

    return
  }, [delay])
}
