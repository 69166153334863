import { Pause, Play } from 'lucide-react'
import { useCallback, useEffect, useRef, useState } from 'react'
import WaveSurfer from 'wavesurfer.js'
import { Button } from './ui/Button'

export const AudioPlayer = ({
  url,
  buttons,
}: {
  url: string
  buttons?: React.ReactNode
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false)

  const audioElementRef = useRef<HTMLAudioElement | null>(null)

  const waveformRef = useRef<HTMLDivElement | null>(null)
  const wavesurferRef = useRef<WaveSurfer | null>(null)

  useEffect(() => {
    if (audioElementRef.current && waveformRef.current) {
      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        barWidth: 4,
        waveColor: getComputedStyle(document.documentElement).getPropertyValue(
          '--wavesurfer',
        ),
        barRadius: 10,
        height: 39, // h-10 - 1px
        media: audioElementRef.current,
        normalize: true,
      })

      const handleFinish = () => setIsPlaying(false)

      wavesurferRef.current.on('finish', handleFinish)

      return () => {
        wavesurferRef.current?.un('finish', handleFinish)
        wavesurferRef.current?.destroy()
      }
    }
    return () => {}
  }, [url])

  const onPlayPause = useCallback(() => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause()
      setIsPlaying(!isPlaying)
    }
  }, [wavesurferRef, isPlaying])

  return (
    <div className="bg-secondary flex h-full grow flex-row items-center gap-1 rounded-md shadow-sm">
      <Button
        size="icon"
        variant="secondary"
        type="button"
        onClick={onPlayPause}
      >
        {isPlaying ? <Pause className="size-4" /> : <Play className="size-4" />}
      </Button>
      <div className="flex h-10 grow flex-col">
        <div ref={waveformRef} />
        <audio src={url} ref={audioElementRef} className="hidden" />
      </div>
      {buttons}
    </div>
  )
}
