import { Trans } from '@lingui/macro'
import { useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { DialogButton } from '../../components/Button'
import { cn } from '../../components/ui/cn'
import { useUser } from '../../components/UserProvider'
import { AlertContext } from './AlertContext'
import { trackVideoAction, TutorialCarousel } from './TutorialCarousel'

const dialogRootElement = document.createElement('div')

document.body.appendChild(dialogRootElement)

export const TutorialPopupDialog: React.FC<
  React.PropsWithChildren<{ onClose: () => void }>
> = ({ onClose }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const { notificationAlertId } = useContext(AlertContext)
  const { uid, role } = useUser()

  const handleExpand = () => setIsExpanded(true)

  const handleClose = async () => {
    if (role === 'AS') {
      await trackVideoAction(uid, 'POPUP_CLOSED', 'popup')
    }
    onClose()
  }

  if (notificationAlertId) return null

  return createPortal(
    <div
      className={cn(
        'fixed z-10 flex',
        isExpanded
          ? 'bottom-0 right-0 h-full w-full items-center justify-center bg-black bg-opacity-50'
          : 'fixed bottom-3 right-3 items-end justify-end',
      )}
      onClick={(e) => {
        if (e.target === e.currentTarget) handleClose()
      }}
    >
      <div
        className={cn(
          'bg-background-dialog dark:bg-background-dialog-dark relative flex flex-col overflow-auto p-2 shadow-xl transition-all duration-500 ease-in-out',
          isExpanded
            ? 'max-h-[95dvh] max-w-[95vw] '
            : 'max-h-[64vh] max-w-[36vw]',
        )}
      >
        {!isExpanded && (
          <div
            className="absolute inset-0 z-20 cursor-pointer bg-transparent"
            onClick={handleExpand}
          />
        )}
        <TutorialCarousel isExpanded={isExpanded} source="popup" />
        <div className="relative z-30 flex flex-row pt-2">
          <DialogButton onClick={handleClose}>
            <Trans>Fermer</Trans>
          </DialogButton>
        </div>
      </div>
    </div>,
    dialogRootElement,
  )
}
