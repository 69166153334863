export type LoadingState = {
  data: null
  loading: true
  error: null
}

export type ErrorState = {
  data: null
  loading: false
  error: Error
}

export type DataState<T> = {
  data: T
  loading: false
  error: null
}

export type AsyncState<T> = LoadingState | ErrorState | DataState<T>

export function loadingState() {
  const loadingState: LoadingState = { data: null, loading: true, error: null }
  return loadingState
}

export function errorState(error: Error) {
  const errorState: ErrorState = { data: null, loading: false, error }
  return errorState
}

export function dataState<T>(data: T) {
  const dataState: DataState<T> = { data, loading: false, error: null }
  return dataState
}
