import { UserDiscussions } from 'common/types'
import React from 'react'
import { Contacts } from 'shared/types/live'
import { FacilityId } from 'shared/types/utils'
import { useUser } from '../../components/UserProvider'
import { useFirebase } from '../../hooks/useFirebase'

type ContactsContext = {
  contacts: Contacts
  discussions: UserDiscussions
  loading: boolean
  error: Error | null
}

export const contactsContext = React.createContext<ContactsContext>({
  contacts: {},
  discussions: {},
  loading: true,
  error: null,
})

interface Props {
  facilityId: FacilityId
}

const OtherProvider: React.FC<React.PropsWithChildren<Props>> = ({
  facilityId,
  children,
}) => {
  const {
    data: contacts,
    loading: loadingContacts,
    error: errorContacts,
  } = useFirebase(`contacts/${facilityId}`)

  const contextValue = {
    contacts: contacts || {},
    discussions: {},
    loading: loadingContacts,
    error: errorContacts,
  }

  return (
    <contactsContext.Provider value={contextValue}>
      {children}
    </contactsContext.Provider>
  )
}

const ASProvider: React.FC<React.PropsWithChildren<Props>> = ({
  facilityId,
  children,
}) => {
  const { uid } = useUser()

  const {
    data: contacts,
    loading: loadingContacts,
    error: errorContacts,
  } = useFirebase(`contacts/${facilityId}`)

  const {
    data: discussions,
    loading: loadingDiscussions,
    error: errorDiscussions,
  } = useFirebase(`discussions/${facilityId}/${uid}`)

  const contextValue = {
    contacts: contacts || {},
    discussions: discussions || {},
    loading: loadingContacts || loadingDiscussions,
    error: errorContacts || errorDiscussions,
  }

  return (
    <contactsContext.Provider value={contextValue}>
      {children}
    </contactsContext.Provider>
  )
}

export const ContactsProvider: React.FC<React.PropsWithChildren<Props>> = ({
  facilityId,
  children,
}) => {
  const { role } = useUser()

  // only AS shall access discussions, therefore only AS shall call useFirebase('discussions')
  if (role !== 'AS') {
    return <OtherProvider facilityId={facilityId}>{children}</OtherProvider>
  }

  return <ASProvider facilityId={facilityId}>{children}</ASProvider>
}
