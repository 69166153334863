import {
  increment as incrementFB,
  serverTimestamp as serverTimestampFB,
} from 'firebase/database'

export function serverTimestamp() {
  // Fake cast to make it compatible with database schema
  return serverTimestampFB() as unknown as number
}

export function increment() {
  // Fake cast to make it compatible with database schema
  return incrementFB(1) as unknown as number
}

export function decrement() {
  return incrementFB(-1) as unknown as number
}
