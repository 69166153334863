// See https://firebase.google.com/docs/database/ios/structure-data#how_data_is_structured_its_a_json_tree

const firebaseKeyRegEx = /[.$[\]#/]/g

export function asFirebaseKey(str: string) {
  // Replace forbidden characters in firebase key
  return str.replace(firebaseKeyRegEx, '_')
}

export function isValidFirebaseKey(str: string) {
  return str.length > 0 && str.match(firebaseKeyRegEx) === null
}
