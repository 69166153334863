import { i18n } from '@lingui/core'
import {
  FALLBACK_LANGUAGE,
  getBrowserLanguage,
  getLanguageFromBCP47Code,
} from 'shared/utils/i18n'

async function loadCatalog(locale: string) {
  try {
    const { messages } = await import(`./locales/${locale}.po`)
    i18n.loadAndActivate({ locale, messages })
  } catch (error) {
    console.error(`Failed to load language ${locale}`, error)
    // Load fallback language
    const { messages } = await import(`./locales/${FALLBACK_LANGUAGE}.po`)
    i18n.loadAndActivate({ locale: FALLBACK_LANGUAGE, messages })
  }
}

export async function loadBrowserLanguage() {
  const browserLang = getBrowserLanguage()
  const langCode = getLanguageFromBCP47Code(browserLang)
  await loadCatalog(langCode)
}
