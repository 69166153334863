import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { ALERT_TYPE_TRANSLATE } from 'shared/types/alert.i18n'
import { ms } from 'shared/utils/time'

export const ALERT_NOTIFICATION_TTL = ms(1, 'hour')

// 🌪️ i18n warning : update accordinly in live/src/common/alert.ts 🌪️
export function translateAlertDisplayText(alert: Alert) {
  if (alert.type === 'BEEP') return t`Bip ${alert.text.replace(/_/g, ' ')}`
  if (alert.keywords)
    return alert.keywords.map((keyword) => `"${keyword}"`).join(', ')
  return i18n._(ALERT_TYPE_TRANSLATE[alert.type])
}
