import { InputHTMLAttributes } from 'react'
import { cn } from './ui/cn'

type InputProps = InputHTMLAttributes<HTMLInputElement>

export const Input = ({ children, ...props }: InputProps) => (
  <input
    className="bg-background-button dark:bg-background-button-dark placeholder-primary dark:placeholder-primary-dark cursor-pointer rounded px-2 py-1 placeholder-opacity-30 focus:outline-none disabled:opacity-50 dark:placeholder-opacity-30"
    {...props}
  >
    {children}
  </input>
)

export const DialogInput = ({ children, className, ...props }: InputProps) => (
  <input
    className={cn(
      'text-dialog dark:text-dialog-dark placeholder-primary dark:placeholder-primary border-2 border-current bg-transparent px-2 py-1 placeholder-opacity-30 focus:outline-none disabled:opacity-50 dark:placeholder-opacity-30',
      className,
    )}
    {...props}
  >
    {children}
  </input>
)
