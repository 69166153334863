import { Settings } from 'luxon'

Settings.throwOnInvalid = true
Settings.defaultLocale = navigator.language // else system language is used

declare module 'luxon' {
  interface TSSettings {
    throwOnInvalid: true
  }
}
