import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { onError } from 'shared/utils/web/error'
import { storage } from '../../firebase'

const MAX_AUDIO_SIZE = 10 * 1024 * 1024 // 10MB
const MAX_IMAGE_SIZE = 5 * 1024 * 1024 // 5MB
const ALLOWED_AUDIO_TYPES = ['audio/wav', 'audio/mpeg', 'audio/mp3']
const ALLOWED_IMAGE_TYPES = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
]

function generateUniqueFileName(): string {
  return `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`
}

function validateFile(
  file: Blob,
  maxSize: number,
  allowedTypes: string[],
): void {
  if (file.size > maxSize) {
    throw new Error(`File size exceeds ${maxSize / 1024 / 1024}MB limit`)
  }
  if (!allowedTypes.includes(file.type)) {
    throw new Error(
      `File type ${file.type} is not allowed. Allowed types: ${allowedTypes.join(', ')}`,
    )
  }
}

async function uploadFile(
  file: Blob | File,
  maxSize: number,
  allowedTypes: string[],
  folder: string,
) {
  try {
    validateFile(file, maxSize, allowedTypes)

    const metadata = {
      contentType: file.type,
      customMetadata: {
        uploadedAt: new Date().toISOString(),
      },
    }

    const name = generateUniqueFileName()
    const fileExtension = file.type.split('/')[1]
    const filePath = `${folder}/${name}.${fileExtension}`

    const storageRef = ref(storage, filePath)
    const uploadTask = await uploadBytes(storageRef, file, metadata)
    const url = await getDownloadURL(uploadTask.ref)

    return [filePath, url] as const
  } catch (error) {
    onError(error)
    return [undefined, undefined] as const
  }
}

export function uploadSound(blob: Blob) {
  return uploadFile(blob, MAX_AUDIO_SIZE, ALLOWED_AUDIO_TYPES, 'audio')
}

export function uploadImage(photo: File) {
  return uploadFile(photo, MAX_IMAGE_SIZE, ALLOWED_IMAGE_TYPES, 'images')
}
