import { Trans } from '@lingui/macro'
import React, { useContext, useMemo } from 'react'
import { ContactType } from 'shared/types/live'
import { DialogButton } from '../../components/Button'
import { useUser } from '../../components/UserProvider'
import { Contact } from './Contact'
import { contactsContext } from './contactsProvider'

export const Contacts: React.FC<{
  setConversationUserId: (_: string) => void
  onClose: () => void
}> = ({ setConversationUserId, onClose }) => {
  const { uid } = useUser()
  const { contacts, discussions } = useContext(contactsContext)

  const sortedContactsEntries = useMemo(
    () =>
      Object.entries(contacts ?? {})
        .filter(([userId, _]) => userId !== uid)
        .sort(([_userId1, { name: name1 }], [_userId2, { name: name2 }]) =>
          name1.localeCompare(name2),
        ),
    [uid, contacts],
  )

  const ContactsBlock: React.FC<{ title: string; type: ContactType }> = ({
    title,
    type,
  }) => (
    <div className="flex flex-col space-y-4">
      <h2 className="border-b border-current text-xl font-bold">{title}</h2>
      {sortedContactsEntries
        .filter(([_, { type: type_ }]) => type_ === type)
        .map(([userId, contact]) => (
          <Contact
            key={userId}
            contact={contact}
            unreadCount={discussions?.[userId]?.unreadCount ?? 0}
            showConversation={() => setConversationUserId(userId)}
          />
        ))}
    </div>
  )

  return (
    <>
      <div className="flex-1 overflow-auto">
        <div className="flex flex-col space-y-6">
          <ContactsBlock title="Contacts" type="user" />
          {/* <ContactsBlock title="Discussions" type="channel" /> */}
          <ContactsBlock title="Externes" type="external" />
        </div>
      </div>
      <div className="flex flex-row">
        <DialogButton onClick={onClose}>
          <Trans>Fermer</Trans>
        </DialogButton>
      </div>
    </>
  )
}
