import { useEffect, useState } from 'react'

export const useRefreshInterval = (timeout: number) => {
  const [timestamp, setTimestamp] = useState(() => Date.now())
  useEffect(() => {
    const intervalId = setInterval(() => setTimestamp(Date.now()), timeout)
    return () => clearInterval(intervalId)
  }, [timeout])

  return timestamp
}
