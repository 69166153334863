import { Database, onValue, ref } from 'firebase/database'
import { useEffect, useMemo, useState } from 'react'
import { DatabaseSubType } from '../firebase/databaseSubType'
import { convertNullToUndefined } from '../firebase/typedMethods'
import {
  AsyncState,
  dataState,
  errorState,
  loadingState,
} from '../types/asyncState'
import { onError } from '../utils/web/error'

interface LoadingState<T> {
  data: Nullable<T>
  loading: true
  error: Error | null
}

interface ErrorState<T> {
  data: Nullable<T>
  loading: boolean
  error: Error
}

interface DataState<T> {
  data: T
  loading: false
  error: null
}

export type MergedState<T> = LoadingState<T> | ErrorState<T> | DataState<T>

export type Nullable<T> = {
  [P in keyof T]: T[P] | null // null while loading or on error
}

export type MergedType<RefPathsMap, DatabaseSchema> = RefPathsMap extends string
  ? Record<string, DatabaseSubType<DatabaseSchema, RefPathsMap>>
  : {
      [key in keyof RefPathsMap]: RefPathsMap[key] extends string
        ? DatabaseSubType<DatabaseSchema, RefPathsMap[key]>
        : never
    }

export function createUseMergedFirebase(database: Database) {
  return function useMergedFirebase<
    DatabaseData extends Record<keyof RefPathsMap, unknown>,
    TransformedData = DatabaseData,
    RefPathsMap extends Record<string, string> = Record<string, string>,
  >(
    // Make sure both of these dependencies are stable, using
    // useMemo and useCallback respectively if needed
    refPathsMap: RefPathsMap,
    transform?: (data: Nullable<DatabaseData>) => TransformedData,
  ) {
    type StateMap = {
      [K in keyof RefPathsMap]: AsyncState<DatabaseData[K]>
    }

    const [stateMap, setStateMap] = useState(() =>
      Object.keys(refPathsMap).reduce((acc, refKey) => {
        return { ...acc, [refKey]: loadingState() }
      }, {} as StateMap),
    )

    useEffect(() => {
      // Update stateMap on refPath changes, adding or removing keys as necessary
      setStateMap((prevStateMap) => {
        const addedKeys = Object.keys(refPathsMap).filter(
          (key) => prevStateMap[key as keyof RefPathsMap] === undefined,
        )
        const removedKeys = Object.keys(prevStateMap).filter(
          (key) => refPathsMap[key as keyof RefPathsMap] === undefined,
        )

        const stateMap = { ...prevStateMap }
        addedKeys.forEach((key) => {
          stateMap[key as keyof RefPathsMap] = loadingState()
        })
        removedKeys.forEach((key) => {
          delete stateMap[key as keyof RefPathsMap]
        })
        return stateMap
      })

      const unsubscribeFunctions = Object.entries(refPathsMap).map(
        ([refKey, refPath]) =>
          onValue(
            ref(database, refPath),
            (snapshot) => {
              setStateMap((prevStateMap) => ({
                ...prevStateMap,
                [refKey]: dataState(convertNullToUndefined(snapshot.val())),
              }))
            },
            (error: Error) => {
              setStateMap((prevStateMap) => ({
                ...prevStateMap,
                [refKey]: errorState(error),
              }))

              onError(error)
            },
          ),
      )

      return () => {
        unsubscribeFunctions.forEach((unsubscribeFunction) =>
          unsubscribeFunction(),
        )
      }
    }, [refPathsMap])

    const mergedState: MergedState<TransformedData> = useMemo(() => {
      const dataMap = {} as Nullable<DatabaseData>

      let loading = false
      let error: Error | null = null

      for (const key in stateMap) {
        const state = stateMap[key]
        // Check if *at least one* state is loading or error
        if (state.loading) loading = true
        if (state.error) error = state.error
        // will be null if loading or error, database type otherwise
        dataMap[key] = state.data
      }

      return {
        data: transform
          ? transform(dataMap)
          : (dataMap as unknown as TransformedData),
        loading,
        error,
      }
    }, [stateMap, transform])

    return mergedState
  }
}
