import { Trans } from '@lingui/macro'
import { ConversationId } from 'common/types'
import React, { useContext, useEffect, useRef } from 'react'
import { Centered } from 'shared/components/Centered'
import { FacilityId, UserId } from 'shared/types/utils'
import { Bubble } from '../../components/conversation/Bubble'
import { useUser } from '../../components/UserProvider'
import { update } from '../../firebaseMethods'
import { useFirebase } from '../../hooks/useFirebase'
import { Composer } from './Composer'
import { contactsContext } from './contactsProvider'

interface Props {
  conversationId: ConversationId | undefined
  userId: UserId
  facilityId: FacilityId
  onClose: () => void
}

export const Conversation: React.FC<Props> = ({
  conversationId,
  userId,
  facilityId,
  onClose,
}) => {
  const { uid } = useUser()
  const { contacts } = useContext(contactsContext)

  const {
    data: conversation,
    loading,
    error,
  } = useFirebase(
    `conversations/${facilityId}/${conversationId ?? 'not_defined'}`,
  )

  const listRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    listRef?.current?.scrollTo(0, listRef?.current?.scrollHeight)
  }, [conversation])

  useEffect(() => {
    if (conversation)
      update(`discussions/${facilityId}/${uid}/${userId}`, { unreadCount: 0 })
  }, [facilityId, uid, userId, conversation])

  if (loading)
    return (
      <Centered>
        <Trans>Chargement...</Trans>
      </Centered>
    )
  if (error)
    return (
      <Centered>
        <Trans>Erreur</Trans>
      </Centered>
    )

  return (
    <>
      <div className="flex flex-row items-center">
        <div className="flex-1 text-center text-2xl font-bold ">
          {contacts[userId].name}
        </div>
        <div
          className="bg-background-phone dark:bg-background-phone-dark flex h-10 w-10 flex-col items-center justify-center rounded-full text-5xl"
          onClick={onClose}
        >
          &times;
        </div>
      </div>
      <div className="flex-1 overflow-y-auto" ref={listRef}>
        {conversation ? (
          Object.entries(conversation).map(([key, bubble]) => (
            <div
              className={`mb-3 flex flex-row ${
                bubble.userId === uid ? 'justify-end' : 'justify-start'
              }`}
              key={key}
            >
              <Bubble bubble={bubble} />
            </div>
          ))
        ) : (
          <Centered>
            <Trans>Aucune conversation</Trans>
          </Centered>
        )}
      </div>

      <Composer
        conversationId={conversationId}
        userId={userId}
        facilityId={facilityId}
      />
    </>
  )
}
