import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { I18nProvider } from '@lingui/react'
import { ErrorBoundary } from '@sentry/react'
import React, { useEffect } from 'react'
import { Centered } from 'shared/components/Centered'
import { ms } from 'shared/utils/time'
import { onError } from 'shared/utils/web/error'
import { YellowButton } from '../../components/Button'
import { CurrentlyPlayingProvider } from '../../components/Player'
import { Theme } from '../../components/Theme'
import { UpdateAppBanner } from '../../components/UpdateAppBanner'
import { UserProvider } from '../../components/UserProvider'
import { useAuth } from '../../hooks/useAuth'
import { useFirebase } from '../../hooks/useFirebase'
import { useIsOnline } from '../../hooks/useIsOnline'
import { loadBrowserLanguage } from '../../i18n'
import { Login } from '../Login/Login'
import { ASContent, AdminContent } from './Content'
import { OfflineScreen } from './OfflineScreen'
import { PasswordReset } from './PasswordReset'

export const App = () => {
  const isOnline = useIsOnline()

  useEffect(() => {
    const asyncLoadBrowserLanguage = async () => await loadBrowserLanguage()
    asyncLoadBrowserLanguage()
  }, [])

  return (
    <React.StrictMode>
      <I18nProvider i18n={i18n}>
        <ErrorBoundary
          fallback={
            <div className="bg-background dark:bg-background-dark text-primary dark:text-primary-dark flex h-screen flex-col items-center justify-center space-y-3">
              <div>
                <Trans>Une erreur est survenue</Trans>
              </div>
              <YellowButton
                onClick={() => {
                  window.location.reload()
                }}
              >
                <Trans>Rafraîchir</Trans>
              </YellowButton>
            </div>
          }
        >
          <Theme />
          {isOnline ? <Authentification /> : <OfflineScreen />}
          <PasswordReset />
          {import.meta.env.MODE !== 'production' && (
            <div className="pointer-events-none fixed bottom-2 right-2 text-[#bef264]">
              <b>STAGING</b>
            </div>
          )}
        </ErrorBoundary>
      </I18nProvider>
    </React.StrictMode>
  )
}

const Authentification: React.FC = () => {
  const { data: user, loading, error } = useAuth()

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(
        () => {
          onError('Authentification takes too long')
          window.location.reload()
        },
        ms(30, 'seconds'),
      )
      return () => clearTimeout(timer)
    }
    return
  }, [loading])

  useEffect(() => {
    if (error !== null) {
      onError(error)
      const timer = setTimeout(
        () => {
          onError('Reload page after Authentification error')
          window.location.reload()
        },
        ms(2, 'minutes'),
      )
      return () => clearTimeout(timer)
    }
    return
  }, [error])

  return (
    <div className="bg-background dark:bg-background-dark text-primary dark:text-primary-dark">
      <div className="flex min-h-screen flex-col text-xl">
        {loading ? (
          <Centered>
            <Trans>Authentification...</Trans>
          </Centered>
        ) : error ? (
          <Centered>
            <Trans>Erreur</Trans>
          </Centered>
        ) : user === null ? (
          <Login />
        ) : (
          <Authorization uid={user.uid} />
        )}
      </div>
      <UpdateAppBanner />
    </div>
  )
}

const Authorization: React.FC<{ uid: string }> = ({ uid }) => {
  const { data: user, loading, error } = useFirebase(`users/${uid}`)

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(
        () => {
          onError('Authorization takes too long')
          window.location.reload()
        },
        ms(30, 'seconds'),
      )
      return () => clearTimeout(timer)
    }
    return
  }, [loading])

  useEffect(() => {
    if (error !== null) {
      onError(error)
      const timer = setTimeout(
        () => {
          onError('Reload page after Authorization error')
          window.location.reload()
        },
        ms(2, 'minutes'),
      )
      return () => clearTimeout(timer)
    }
    return
  }, [error])

  useEffect(() => {
    if (loading || error) return
    if (user === undefined) onError(`No user credentials set for uid ${uid}`)
  }, [loading, error, user, uid])

  return loading ? (
    <Centered>
      <Trans>Autorisation...</Trans>
    </Centered>
  ) : error ? (
    <Centered>
      <Trans>Erreur</Trans>
    </Centered>
  ) : !user ? (
    <Centered>
      <Trans>Utilisateur invalide</Trans>
    </Centered>
  ) : (
    <UserProvider user={{ uid, ...user }}>
      <CurrentlyPlayingProvider>
        {user.role === 'admin' ? <AdminContent /> : <ASContent />}
      </CurrentlyPlayingProvider>
    </UserProvider>
  )
}
