import {
  typedGet,
  typedPush,
  typedRemove,
  typedSet,
  typedUpdate,
} from 'shared/firebase/typedWebMethods'

import { DatabaseSchema } from 'common/databaseSchema'
import { database } from './firebase'

export const update = typedUpdate<DatabaseSchema>(database)
export const set = typedSet<DatabaseSchema>(database)
export const push = typedPush<DatabaseSchema>(database)
export const remove = typedRemove<DatabaseSchema>(database)
export const get = typedGet<DatabaseSchema>(database)
