import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'
import { AlertType, Category } from './alert'

// 🌪️ i18n warning : update accordinly in shared/src/types/alert.ts 🌪️
export const CATEGORY_DISPLAY_TRANSLATE: Record<Category, MessageDescriptor> = {
  emergency: msg`Urgence`,
  comfort: msg`Confort`,
}

// 🌪️ i18n warning : update accordinly in shared/src/types/alert.ts 🌪️
export const ALERT_TYPE_TRANSLATE: Record<AlertType, MessageDescriptor> = {
  AS_CALL: msg`Appel de confort`,
  BEEP: msg`Bip machine`,
  BOOM: msg`Choc inquiétant`,
  BREATHING: msg`Gêne respiratoire`,
  BREATHING_ASTHMA: msg`Respiration sifflante`,
  BREATHING_EDEMA: msg`Détresse respiratoire`,
  BREATHING_JANUS: msg`Problème respiratoire (JANUS)`,
  BREATHLESSNESS: msg`Essoufflement prolongé`,
  CANCEL_ROOM_EXIT: msg`Non sortie de chambre`,
  CLICKER: msg`Appel bouton`,
  CRY: msg`Pleurs`,
  DRUM: msg`Tambourinage`,
  FALL_JANUS: msg`Choc inquiétant (JANUS)`,
  PUKE: msg`Vomissement`,
  PUKE_JANUS: msg`Vomissement (JANUS)`,
  ROOM_EXIT: msg`Sortie de chambre`,
  SPEECH: msg`Appel`,
  TEETH_GRINDING: msg`Grincement de dents`,
  WAKEUP: msg`Réveil`,
  WHINE: msg`Gémissement`,
  YELL: msg`Cri`,
}
