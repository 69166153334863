import { ButtonHTMLAttributes } from 'react'
import { cn } from './ui/cn'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  ...props
}) => (
  <button
    className={cn(
      'cursor-pointer px-2 py-1 text-center focus:outline-none disabled:cursor-not-allowed disabled:opacity-50',
      className,
    )}
    {...props}
  >
    {children}
  </button>
)

export const GrayButton: React.FC<ButtonProps> = ({
  children,
  className,
  ...props
}) => (
  <Button
    className={cn(
      'bg-background-button dark:bg-background-button-dark rounded',
      className,
    )}
    {...props}
  >
    {children}
  </Button>
)

export const YellowButton: React.FC<ButtonProps> = ({
  children,
  className,
  ...props
}) => (
  <Button
    className={cn(
      'border-background-alert dark:border-background-alert-dark border-2',
      className,
    )}
    {...props}
  >
    {children}
  </Button>
)

export const DialogButton: React.FC<ButtonProps> = ({ children, ...props }) => (
  <button
    className="flex-1 cursor-pointer self-center break-words border-2 border-current bg-transparent px-3 py-1 text-center focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
    {...props}
  >
    {children}
  </button>
)

export const ToggleDialogButton: React.FC<
  ButtonProps & { selected: boolean }
> = ({ children, selected, ...props }) => (
  <button
    className={`cursor-pointer self-center break-words rounded-md border-2 border-current px-3 py-1 text-center focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 ${
      selected ? 'bg-black bg-opacity-30' : 'bg-transparent'
    }`}
    {...props}
  >
    {children}
  </button>
)
