import { Trans } from '@lingui/macro'
import React, { useContext } from 'react'
import Phone from '../../icons/phone-call.svg?react'
import PhoneSlashIcon from '../../icons/phone-slash.svg?react'
import { EmergencyCallContext } from './EmergencyCallContext'

export const EmergencyCall: React.FC = () => {
  const {
    remoteVideoRef,
    localVideoRef,
    startEmergencyCall,
    hangUpCall,
    amICalling,
    amICalled,
    isCallInProgress,
    isCallAnswered,
  } = useContext(EmergencyCallContext)

  return (
    <div className="flex flex-1 flex-col">
      <div
        className={`relative pt-[66%] ${
          isCallInProgress && (!isCallAnswered || amICalling || amICalled)
            ? 'flex flex-col'
            : 'hidden'
        }`}
      >
        {amICalling && (
          <div className="absolute inset-0 flex flex-col items-center justify-center bg-black text-white">
            <Trans>Appel d'urgence en cours, veuillez patienter...</Trans>
          </div>
        )}
        <video
          className="absolute inset-0 h-full w-full object-cover"
          ref={remoteVideoRef}
          autoPlay
          playsInline
        />
        <video
          className="absolute bottom-2 right-2 h-1/3 w-1/3 object-cover"
          ref={localVideoRef}
          autoPlay
          muted
          playsInline
        />
        {isCallInProgress && !amICalling && !isCallAnswered && (
          <div
            className="bg-background-emergency dark:bg-background-emergency-dark text-menu dark:text-menu-dark absolute inset-0 flex cursor-pointer flex-col items-center justify-center space-y-3"
            onClick={startEmergencyCall}
          >
            <div>
              <Trans>Répondre à l'appel d'urgence</Trans>
            </div>
            <Phone
              fill="currentColor"
              className="animate-heartbeat w-16 rounded-full"
            />
          </div>
        )}
      </div>
      {(amICalling || amICalled) && (
        <div
          className="bg-background-emergency dark:bg-background-emergency-dark text-menu dark:text-menu-dark flex flex-1 flex-row items-center justify-center space-x-2 border border-current py-3"
          onClick={hangUpCall}
        >
          <PhoneSlashIcon fill="currentColor" className="w-6" />
          <b>
            <Trans>Raccrocher</Trans>
          </b>
        </div>
      )}
    </div>
  )
}
