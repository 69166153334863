import React from 'react'
import { createPortal } from 'react-dom'

const dialogRootElement = document.createElement('div')

document.body.appendChild(dialogRootElement)

export const USER_CLOSED_DIALOG = 'USER_CLOSED_DIALOG'

export const Dialog: React.FC<
  React.PropsWithChildren<{ onClose: () => void }>
> = ({ children, onClose }) => {
  return createPortal(
    <div
      className="fixed inset-0 z-10 flex flex-col items-center justify-center bg-black bg-opacity-50"
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose()
      }}
    >
      <div className="max-h-[95vh] max-w-[95vw] overflow-auto">{children}</div>
    </div>,
    dialogRootElement,
  )
}
