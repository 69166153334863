import React from 'react'

interface Props {
  fullScreen?: boolean
}

export const Centered: React.FC<React.PropsWithChildren<Props>> = ({
  fullScreen,
  children,
}) => (
  <div
    className={`flex flex-1 flex-col items-center justify-center ${fullScreen ? 'min-h-screen' : ''}`}
  >
    {children}
  </div>
)
