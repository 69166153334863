import React from 'react'
import { Deferred } from 'shared/utils/web/deferred'
import { GrayButton } from '../../components/Button'
import { Dialog, USER_CLOSED_DIALOG } from '../../components/Dialog'

export const ValidateAlertDialog: React.FC<{
  deferred: Deferred<void>
}> = ({ deferred }) => {
  return (
    <Dialog onClose={() => deferred.reject(USER_CLOSED_DIALOG)}>
      Valider cette alerte ?
      <div className="flex flex-row gap-3">
        <GrayButton
          className="flex-auto"
          onClick={() => deferred.reject(USER_CLOSED_DIALOG)}
        >
          Annuler
        </GrayButton>
        <GrayButton className="flex-auto" onClick={() => deferred.resolve()}>
          Confirmer
        </GrayButton>
      </div>
    </Dialog>
  )
}
