import React from 'react'
import { Contact as ContactType } from 'shared/types/live'
import MessageIcon from '../../icons/message.svg?react'
import PhoneIcon from '../../icons/phone.svg?react'

interface Props {
  contact: ContactType
  unreadCount: number
  showConversation: () => void
}

export const Contact: React.FC<Props> = ({
  contact: { name, zone, phoneNumber, type },
  unreadCount,
  showConversation,
}) => {
  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-col">
        <div>{zone ?? name}</div>
        {zone !== undefined && <div className="text-base italic">{name}</div>}
      </div>
      <div className="flex flex-row items-center space-x-6">
        {phoneNumber && (
          <div className="bg-background-phone dark:bg-background-phone-dark flex h-11 w-11 flex-col items-center justify-center rounded-full">
            <PhoneIcon
              fill="currentColor"
              className="h-6"
              onClick={() => callPhone(phoneNumber)}
            />
          </div>
        )}
        {type === 'user' && (
          <div className="bg-background-phone dark:bg-background-phone-dark flex h-11 w-11 flex-col items-center justify-center rounded-full">
            <div
              className="flex flex-row items-center"
              onClick={() => showConversation()}
            >
              <MessageIcon fill="currentColor" className="h-6" />
              {unreadCount > 0 && (
                <div className="bg-background-notification dark:bg-background-notification-dark -ml-3 mb-3 h-4 w-4 rounded-full"></div>
              )}
            </div>
          </div>
        )}
        {/* {type === 'channel' && (
          <div className="bg-background-phone dark:bg-background-phone-dark flex h-11 w-11 flex-col items-center justify-center rounded-full bg-opacity-30 dark:bg-opacity-30">
            <MessageIcon fill="primary" className="h-6" />
          </div>
        )} */}
      </div>
    </div>
  )
}

function callPhone(phoneNumber: string) {
  if (window.Android?.onCallStarted) window.Android.onCallStarted(phoneNumber)
}
