import {
  Database,
  get,
  push,
  ref,
  remove,
  set,
  update,
} from 'firebase/database'

import {
  getPushKey as getPushKeyTyped,
  get as getTyped,
  push as pushTyped,
  remove as removeTyped,
  set as setTyped,
  update as updateTyped,
} from './typedMethods'

export function typedUpdate<DatabaseSchema>(database: Database) {
  return updateTyped<DatabaseSchema>((path: string, value: object) =>
    update(ref(database, path), value),
  )
}

export function typedSet<DatabaseSchema>(database: Database) {
  return setTyped<DatabaseSchema>((path: string, value: unknown) =>
    set(ref(database, path), value),
  )
}

export function typedPush<DatabaseSchema>(database: Database) {
  return pushTyped<DatabaseSchema>((path: string, value: unknown) =>
    push(ref(database, path), value),
  )
}

export function typedGetPushKey<DatabaseSchema>(database: Database) {
  return getPushKeyTyped<DatabaseSchema>((path: string) =>
    push(ref(database, path)),
  )
}

export function typedRemove<DatabaseSchema>(database: Database) {
  return removeTyped<DatabaseSchema>((path: string) =>
    remove(ref(database, path)),
  )
}

export function typedGet<DatabaseSchema>(database: Database) {
  return getTyped<DatabaseSchema>((path: string) => get(ref(database, path)))
}
