export class Deferred<T> {
  promise: Promise<T>
  resolve: (value: T | PromiseLike<T>) => void = () => {
    // do nothing
  }
  reject: (reason?: unknown) => void = () => {
    // do nothing
  }
  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }
}
