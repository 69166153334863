import { Language } from 'shared/types/i18n'
import { FALLBACK_LANGUAGE } from 'shared/utils/i18n'
import { EN_MEDIA_SOURCE } from './en'
import { FR_MEDIA_SOURCE } from './fr'

export type MediaSources = {
  emergency_call_video_source: string
  oso_ai_video_source: string
}

const mediaSets: Record<string, MediaSources> = {
  fr: FR_MEDIA_SOURCE,
  en: EN_MEDIA_SOURCE,
}

export function getMediaSourcesByLanguage(lang: Language): MediaSources {
  return (
    mediaSets[lang] ?? mediaSets[FALLBACK_LANGUAGE] // Fallback
  )
}
