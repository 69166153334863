import { Trans } from '@lingui/macro'
import React, { ChangeEvent, useState } from 'react'
import { Deferred } from 'shared/utils/web/deferred'
import { DialogButton } from '../../components/Button'
import { Dialog, USER_CLOSED_DIALOG } from '../../components/Dialog'
import { DialogInput } from '../../components/Input'

export const ChangeRoomNameDialog: React.FC<{
  room: string
  initialRoomName: string
  deferred: Deferred<string>
}> = ({ room, initialRoomName, deferred }) => {
  const [roomName, setRoomName] = useState(initialRoomName)

  function validate() {
    deferred.resolve(roomName.trim())
  }

  return (
    <Dialog onClose={() => deferred.reject(USER_CLOSED_DIALOG)}>
      <Trans>Associer un nom à la chambre {room}</Trans>
      <DialogInput
        autoFocus
        onKeyUp={(event) => {
          if (event.key === 'Enter') validate()
        }}
        value={roomName}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setRoomName(e.currentTarget.value)
        }
      />
      <div className="flex flex-row space-x-4 self-center">
        <DialogButton onClick={() => deferred.reject(USER_CLOSED_DIALOG)}>
          <Trans>Annuler</Trans>
        </DialogButton>
        <DialogButton onClick={validate}>
          <Trans>Valider</Trans>
        </DialogButton>
      </div>
    </Dialog>
  )
}
