import { t } from '@lingui/macro'
import { LoaderCircle, Mic, Square, Trash } from 'lucide-react'
import React, { useCallback, useEffect, useRef } from 'react'
import { useReactMediaRecorder } from 'react-media-recorder-2'
import { ms } from 'shared/utils/time'
import { AudioPlayer } from './AudioPlayer'
import { Button } from './ui/Button'
import { Input } from './ui/Input'

const MAX_AUDIO_DURATION = ms(20, 'seconds')
const MIN_AUDIO_DURATION = ms(1, 'seconds')

interface AudioTextInputProps {
  text: string
  onTextChanged: (text: string) => void
  audioBlob: Blob | null
  onAudioRecorded: (blob: Blob | null) => void
  placeholder: string
  autofocus?: boolean
}

export const AudioTextInput: React.FC<AudioTextInputProps> = ({
  text,
  onTextChanged,
  audioBlob,
  onAudioRecorded,
  placeholder,
  autofocus,
}) => {
  const recordingTimerRef = useRef<NodeJS.Timeout | null>(null)
  const startRef = useRef<number | null>(null)

  const { status, startRecording, stopRecording, clearBlobUrl, mediaBlobUrl } =
    useReactMediaRecorder({
      audio: true,
      onStart: () => {
        startRef.current = new Date().valueOf()
        return
      },
      onStop: (_blobUrl: string, blob: Blob) => {
        if (startRef.current) {
          const duration = new Date().valueOf() - startRef.current

          if (duration >= MIN_AUDIO_DURATION) {
            onAudioRecorded(blob)
          } else {
            onAudioRecorded(null)
          }
          startRef.current = null
        }
      },
    })

  useEffect(() => {
    if (!audioBlob && status !== 'recording') {
      clearBlobUrl()
    }
  }, [audioBlob, clearBlobUrl, status])

  const stop = useCallback(() => {
    stopRecording()
    if (recordingTimerRef.current) {
      clearTimeout(recordingTimerRef.current)
      recordingTimerRef.current = null
    }
  }, [stopRecording])

  const handleStartRecording = useCallback(
    (event: React.MouseEvent | React.TouchEvent) => {
      event.stopPropagation()

      if (status !== 'recording') {
        startRecording()

        recordingTimerRef.current = setTimeout(() => {
          stop()
        }, MAX_AUDIO_DURATION)
      }
    },
    [status, startRecording, stop],
  )

  const handleStopRecording = useCallback(
    (event: React.MouseEvent | React.TouchEvent) => {
      event.stopPropagation()
      stop()
    },
    [stop],
  )

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value
    onTextChanged(newText)
  }

  const clearAudio = () => {
    onAudioRecorded(null)
    clearBlobUrl()
  }

  return (
    <div className="flex w-full items-center space-x-2">
      <div className="flex h-full grow flex-row items-center justify-center">
        {status === 'acquiring_media' ? (
          <LoaderCircle className="h-8 w-8 animate-spin" />
        ) : status === 'recording' ? (
          <RecordingPulse />
        ) : status === 'stopped' && audioBlob && mediaBlobUrl ? (
          <AudioPlayer
            url={mediaBlobUrl}
            buttons={
              <Button
                size="icon"
                onClick={clearAudio}
                variant="destructive"
                type="button"
              >
                <Trash className="size-4" />
              </Button>
            }
          />
        ) : (
          <Input
            value={text}
            onChange={handleTextChange}
            className="h-10 flex-grow rounded-sm px-1 text-xl"
            placeholder={placeholder}
            autoFocus={autofocus}
          />
        )}
      </div>
      <Button
        disabled={status === 'stopping'}
        size="icon"
        variant={status === 'recording' ? 'destructive' : 'secondary'}
        type="button"
        onTouchStart={handleStartRecording}
        onTouchEnd={handleStopRecording}
      >
        {status === 'recording' ? (
          <Square className="size-4" />
        ) : (
          <Mic className="size-4" />
        )}
        <span className="sr-only">
          {status === 'recording'
            ? t`Arrêter l'enregistrement`
            : t`Démarrer l'enregistrement`}
        </span>
      </Button>
    </div>
  )
}

const RecordingPulse = () => {
  return (
    <div className="h-9 py-2">
      <span className="relative flex size-5">
        <span className="bg-destructive absolute inline-flex h-full w-full animate-ping rounded-full opacity-75"></span>
        <span className="bg-destructive relative inline-flex size-5 rounded-full"></span>
      </span>
    </div>
  )
}
